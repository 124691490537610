/* @font-face {
    font-family: 'VerveineW06';
    src: local(VerveineW06-Regular),
      url(./fonts/VerveineW06-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
} */

.MuiMenu-paper {
  top: 50px !important;
  left: 107% !important;
  /* right: 10% !important; */
}

button:focus {
  outline: none;
}

select {
  height: 4rem;
  background: white;
  border: 1px solid #cacaca;
  padding-left: 10px;
}
